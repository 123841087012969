@import 'react-toastify/dist/ReactToastify.css';

body{
    background-color: #f5f7f8;
}

.active {
    background-color: #373737;
    color: white;
  }
  
/* width */
.supportonly::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
.supportonly::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
.supportonly::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
.supportonly::-webkit-scrollbar-thumb:hover {
    background: #555;
  }